<template>
  <template v-if="product.PriceInPoints && product.PriceInPoints.Amount > 0">
    <span
      v-if="product.PriceInPoints.Amount === product.PriceInPoints.WithDiscount"
      class="v-cart-price v-product-price"
    >
      <common-currency
        show-points
        :amount="product.PriceInPoints.Amount * productCount"
        data-test-id="product-points-price"
      />
    </span>

    <span
      v-if="product.PriceInPoints.Amount !== product.PriceInPoints.WithDiscount"
      class="v-cart-price v-product-price-old"
    >
      <common-currency
        show-points
        :amount="product.PriceInPoints.Amount * productCount"
        data-test-id="product-points-price-old"
      />
    </span>
    <span
      v-if="product.PriceInPoints.Amount !== product.PriceInPoints.WithDiscount"
      class="v-cart-price v-product-price-new"
    >
      <common-currency
        show-points
        :amount="product.PriceInPoints.WithDiscount * productCount"
        data-test-id="product-points-price-new"
      />
    </span>
  </template>
  <template v-else>
    <span
      v-if="product.Price.Amount === product.Price.WithDiscount"
      class="v-cart-price v-product-price"
    >
      <common-currency
        :amount="product.Price.Amount * productCount"
        data-test-id="product-price"
      />
    </span>

    <span
      v-if="product.Price.Amount !== product.Price.WithDiscount"
      class="v-cart-price v-product-price-old"
    >
      <common-currency
        :amount="product.Price.Amount * productCount"
        data-test-id="product-price-old"
      />
    </span>
    <span
      v-if="product.Price.Amount !== product.Price.WithDiscount"
      class="v-cart-price v-product-price-new"
    >
      <common-currency
        :amount="product.Price.WithDiscount * productCount"
        data-test-id="product-price-new"
      />
    </span>
  </template>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

const { calcWithCount, product } = defineProps<{
  product: CartItem
  calcWithCount?: boolean
}>()

const productCount = computed(() => (calcWithCount ? product.Count : 1))
</script>

<style lang="scss">
.v-cart-price {
  .v-currency-wrapper {
    display: inline-flex;
    align-items: baseline;
    justify-content: center;
    text-decoration: inherit;
  }

  &.v-product-price {
    font-weight: 700;
  }

  &.v-product-price-old {
    text-decoration: line-through;
    opacity: 0.7;
    display: block;
    font-size: 80%;
    font-weight: 700;
  }

  &.v-product-price-new {
    display: block;
    font-weight: 700;
  }
}
</style>
